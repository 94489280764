import PATHS from '../paths';

const theme = {
  config: {
    themeName: 'hust', // Tên trùng với folder theme
    svg: {
      color: '',
    },
    loginHomePath: PATHS.listExam,
    guestHomePath: PATHS.exam,
    features: [
      /* PATHS.tablePoint */
    ],
    excludePaths: [PATHS.classes, PATHS.classesDetail, PATHS.testTaker],
    auth: {
      withFriend: false, // Không lấy thêm friend trong request /user/info
      register: 'full', // full || basic,
      //loginRedirect: `${PATHS.listExam}`,
      loginRedirect: `${PATHS.listExam}`,
      registerRedirect: PATHS.listExam,
    },
    /*  showOrderTab: true, */
    copyRightLine1: 'TSA.HUST.EDU.VN',
    copyRightLine2: 'Copyright by HUST and FPT IS',
  },
};

export default theme;
